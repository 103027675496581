<template>
    <div>
        <h2><router-link :to="{ name: 'category' }">จัดการหมวดหมู่</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}หมวดหมู่</h2>
        <v-card class="mt-4">
          <v-card-title>ประเภทช่องทาง</v-card-title>

          <v-card-text>
            <v-form v-model="isFormValid">
              <v-row>
                <v-col cols="12" md="6">
                  <delay-autocomplete
                    :url="'/product/category/parentList'"                
                    label="หมวดหมู่แม่"
                    placeholder="หมวดหมู่แม่ (เว้นว่างหากไม่มี)"
                    outlined
                    v-model="formData.parent"
                    item-text="name"
                    item-value="id"
                    dense                
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    return-object
                    hide-details="auto" 
                    :lazySeatch="true"
                  >
                  </delay-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อหมวดหมู่ *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>            
          </v-card-text>          

          <v-card-actions>              
            <v-btn
              color="primary"               
              large          
              :disabled="!isFormValid"
              :loading="sendingData"
              @click="submitForm"
              v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
            >
              {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
            </v-btn>

            <v-btn
              color="secondary"               
              outlined                 
              large
              :disabled="sendingData"
              @click="$router.go(-1)"
            >
              กลับ
            </v-btn>                
          </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: null,
        parent: null,
        parent_id: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    'formData.parent': function (value) {
      if (!value) return (this.formData.parent_id = null)

      this.formData.parent_id = value.id
    },
  },
  async created() {
    const category_id = this.$route.params.category_id

    if (category_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Product'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { name, parent } = await asyncGet('/product/category/' + category_id)

      this.formData = {
        name: name,
        parent: parent,
        parent_id: !!parent ? parent.id : null,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const category_id = this.$route.params.category_id
        if (category_id == 'create') await asyncPost('/product/category', this.formData)
        else {
          if (!this.formData.parent_id) delete this.formData.parent_id

          await asyncUpdate('/product/category/' + category_id, this.formData)
        }

        this.$router.push({ name: 'category' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>